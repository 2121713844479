let _ = require('lodash');
module.exports = {

    data: function () {
        return {
            formError: null
        }
    },

    methods: {

        validateEmail(email) {
            let mailFormat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            return !!(email && email.match(mailFormat));

        },

        fullName(object){
            return ((object!=='' && object !== 'undefined') ?  (object.first_name + ' ' + object.last_name) : '');
        },

    },

    filters: {
        str_limit(string, limit){
            if (string) {
                let msg = string.substring(0, limit);
                if (string.length > limit) {
                    msg = msg + ' ...';
                }
                return msg;
            } else {
                return '';
            }
        },

    },

    computed: {

        formValidationErrors: function () {
            return this.formError ? this.formError.errors : []
        },

        apiRequestUrl: function () {
            return this.$appUrl;
        },
    }
};