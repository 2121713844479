var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "header",
    { staticClass: "d-flex flex-column justify-content-center" },
    [
      _c(
        "div",
        { staticClass: "d-flex justify-content-between px-3 px-md-4 px-lg-5" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("div", [
            _c(
              "div",
              { staticClass: "language-select d-inline-block" },
              [
                _vm._l(_vm.initialData.languages, function (lang, index) {
                  return [
                    index === _vm.initialData.languages.length - 1
                      ? [_vm._v(" |")]
                      : _vm._e(),
                    _vm._v(" "),
                    _c("a", { attrs: { href: lang.localUrl } }, [
                      _vm._v(_vm._s(lang.local)),
                    ]),
                  ]
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "logo-right d-inline-block ml-3 ml-lg-4" },
              [
                _c("img", {
                  attrs: { src: _vm.imgPath, alt: _vm.initialData.city },
                }),
              ]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "navbar-brand",
        attrs: { href: "http://www.milotag.com", target: "_blank" },
      },
      [_c("img", { attrs: { src: "/images/logo-milo.svg", alt: "milo" } })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }