<template>
    <div class="form-group date" :class="formGroupClass">
        <label v-if="label" :for="name" class="control-label">{{ label }}</label>
        <div>
            <input ref="input"
                   class="form-control"
                   :name="name"
                   :id="name"
                   :type="type"
                   :value="value"
                   :placeholder="placeholder"
                   :disabled="disabled"
                   :locale="locale"
                   @input="updateValue($event.target.value)"
            />
            <span class="btn btn-dob">
                <img src="/images/down-chevron.svg" alt="chevron">
            </span>
        </div>
        <p v-if="hasError" class="text-danger helper-block">
            {{ error }}
        </p>
    </div>
</template>
<script>
    require('bootstrap-daterangepicker');
    require('bootstrap-daterangepicker/daterangepicker.css');
    import { datePickerLangJson } from '../../datepicker-language-json';

    export default {
        props: {
            'type': {
                type: String,
                default: 'text'
            },
            'value': {
                default: ''
            },
            'placeholder': {
                type: String,
                default: ''
            },
            'locale': {
                type: String,
                default: 'en'
            },
            'format': {
                type: String,
                default: 'YYYY/MM/DD'
            },
            'error': {
                type: String,
                default: null
            },
            'label': {
                type: String,
                default: null
            },
            'name': {
                type: String,
                default: ''
            },
            'disabled': {
                type: Boolean,
                default: false
            },
            'side-by-side' : {
                type: Boolean,
                default: false
            },
            'defaultDate': {
                default: null
            },
            'show-drop-down': {
                type: Boolean,
                default :false
            },
        },

        methods: {
            updateValue: function (value) {
                this.$emit('input', value);
            }
        },

        computed: {
            hasError() {
                return !!this.error;
            },

            formGroupClass() {
                var c = {'has-error': this.hasError};

                if (this.name) {
                    c['form-group-' + this.name] = this.name;
                }

                return c;
            }
        },

        mounted() {
            let self = this;

            let startDate = (self.value) ? self.value : self.defaultDate;

            $(self.$refs.input).daterangepicker({
                locale: {
                    format: this.format,
                    daysOfWeek: datePickerLangJson[this.locale].daysOfWeek,
                    monthNames: datePickerLangJson[this.locale].monthNames,
                },
                timePicker: this.sideBySide,
                singleDatePicker: true,
                showCustomRangeLabel: true,
                showDropdowns: true,
                startDate: moment().subtract(5860, 'days'),
            }).on('apply.daterangepicker', function(ev, picker) {
                self.updateValue($(self.$refs.input).val());
            }).on('cancel.daterangepicker', function(ev, picker) {
                $(self.$refs.input).val('');
                self.updateValue('');
            }).on('hide.daterangepicker', function(ev, picker) {
                self.updateValue($(self.$refs.input).val());
            });

            setTimeout(() => {
                startDate = (self.value) ? self.value : self.defaultDate;
                if(startDate) {
                    $(self.$refs.input).data('daterangepicker').setStartDate(startDate);
                    $(self.$refs.input).data('daterangepicker').setEndDate(startDate);
                } else {
                    self.updateValue(null);
                }
            }, 500);


            $('.btn-dob').on('click', function () {
                $(self.$refs.input).trigger('focus');
            });

        },
    }
</script>