<template>
    <p class="text-danger error-text" v-show="error">{{ error }}</p>
</template>
<script>
    export default {
        props: ['errors'],

        computed: {
            error(){
                return this.errors && this.errors.length ?  this.errors[0]: null;
            }
        }

    }
</script>