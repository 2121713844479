<style>
    .pac-container {
        z-index: 9999;
    }
</style>
<template>
    <div v-if="showAddBtn || showSearchBtn">
        <div class="input-group">
            <input
                    ref="autocomplete"
                    type="text"
                    :class="classname"
                    :id="id"
                    :placeholder="placeholder"
                    v-model="autocompleteText"
                    @focus="onFocus()"
                    @blur="onBlur()"
                    @change="onChange"
                    @keypress="onKeyPress"
                    @keyup="onKeyUp"
                    autocomplete="new-password"
                    aria-describedby="basic-addon2"
                    autofill="off"
            />

            <div class="input-group-append">
                <button @click="onClickAdd" v-if="showAddBtn" class="btn btn-outline-white">Add Location</button>
                <button @click="onClickSearch" v-if="showSearchBtn" class="btn btn-outline-white">Search</button>
            </div>
        </div>
        <div v-if="error" class="text-danger error-text">
            {{error}}
        </div>
    </div>
    <div v-else>
        <input
                ref="autocomplete"
                type="text"
                :class="classname"
                :id="id"
                :placeholder="placeholder"
                v-model="autocompleteText"
                @focus="onFocus()"
                @blur="onBlur()"
                @change="onChange"
                @keypress="onKeyPress"
                @keyup="onKeyUp"
                autocomplete="new-password"
                aria-describedby="basic-addon2"
                autofill="off"
        />
    </div>
</template>

<script>
    export default {
        props: {
            id: {
                type: String,
                required: true
            },

            classname: String,

            placeholder: {
                type: String,
                default: 'Start typing'
            },

            types: {
                type: String,
                default: 'address'
            },

            country: {
                type: [String, Array],
                default: null
            },

            enableGeolocation: {
                type: Boolean,
                default: false
            },

            showAddBtn : {
                type: Boolean,
                default: false
            },

            showSearchBtn : {
                type: Boolean,
                default: false
            },

            errorMsg: {
                type: String,
                default: 'Please select location from suggestions.'
            },

            defaultAddress : {
                type: String,
                default: null
            },

        },

        data: function () {
            return {
                /**
                 * The Autocomplete object.
                 *
                 * @type {Autocomplete}
                 * @link https://developers.google.com/maps/documentation/javascript/reference#Autocomplete
                 */
                autocomplete: null,

                /**
                 * The Location Object
                 * @type {Location Object}
                 */
                autocompleteObj: null,

                /**
                 * Autocomplete input text
                 * @type {String}
                 */
                autocompleteText: '',

                /**
                 *  @type {String}
                 */
                error: null,

            }
        },

        watch: {
            autocompleteText: function (newVal, oldVal) {
                this.$emit('inputChange', { newVal, oldVal }, this.id);
            },
            country: function(newVal, oldVal) {
                this.autocomplete.setComponentRestrictions({
                    country: this.country === null ? [] : this.country
                });
            },
        },

        mounted: function() {
            const options = {};

            if (this.types) {
                options.types = [this.types];
            }

            if (this.country) {
                options.componentRestrictions = {
                    country: this.country
                };
            }

            this.autocomplete = new google.maps.places.Autocomplete(
                document.getElementById(this.id),
                options
            );

            this.autocomplete.addListener('place_changed', () => {

                let place = this.autocomplete.getPlace();

                if (!place.geometry) {
                    // User entered the name of a Place that was not suggested and
                    // pressed the Enter key, or the Place Details request failed.
                    this.$emit('no-results-found', place, this.id);
                    return;
                }

                let addressComponents = {
                    street_number: 'short_name',
                    route: 'long_name',
                    locality: 'long_name',
                    administrative_area_level_1: 'short_name',
                    administrative_area_level_2: 'county',
                    country: 'long_name',
                    postal_code: 'short_name'
                };

                let returnData = {};

                if (place.address_components !== undefined) {
                    // Get each component of the address from the place details
                    for (let i = 0; i < place.address_components.length; i++) {
                        let addressType = place.address_components[i].types[0];

                        if (addressComponents[addressType]) {
                            let val = place.address_components[i][addressComponents[addressType]];
                            returnData[addressType] = val;
                        }
                    }

                    returnData['latitude'] = place.geometry.location.lat();
                    returnData['longitude'] = place.geometry.location.lng();

                    // return returnData object and PlaceResult object
                    this.$emit('placechanged', returnData, place, this.id);

                    this.autocompleteObj = returnData;
                    // update autocompleteText then emit change event
                    this.autocompleteText = document.getElementById(this.id).value;
                    this.onChange()
                }
            });

            setTimeout(() => {
                if(this.defaultAddress){
                    this.autocompleteText = this.defaultAddress;
                }
            }, 500);
        },

        methods: {
            /**
             * When the input gets focus
             */
            onFocus() {
                this.geolocate();
                this.$emit('focus');
            },

            /**
             * When the input loses focus
             */
            onBlur() {
                this.$emit('blur');
            },

            /**
             * When the input got changed
             */
            onChange() {
                this.isDisableButton = false;
                this.$emit('change', (this.autocomplete.getPlace()) ? this.autocomplete.getPlace() : this.autocompleteText);
            },

            /**
             * When a key gets pressed
             * @param  {Event} event A keypress event
             */
            onKeyPress(event) {
                this.$emit('keypress', event);
            },

            /**
             * When a keyup occurs
             * @param  {Event} event A keyup event
             */
            onKeyUp(event) {
                this.$emit('keyup', event);
            },

            /**
             * Clear the input
             */
            clear() {
                this.autocompleteText = '';
                this.isDisableButton = true;
                this.autocompleteObj = null;
            },

            /**
             * Focus the input
             */
            focus() {
                this.$refs.autocomplete.focus()
            },

            /**
             * Blur the input
             */
            blur() {
                this.$refs.autocomplete.blur()
            },

            /**
             * Update the value of the input
             * @param  {String} value
             */
            update (value) {
                this.autocompleteText = value
            },

            // Bias the autocomplete object to the user's geographical location,
            // as supplied by the browser's 'navigator.geolocation' object.
            geolocate() {
                if (this.enableGeolocation) {
                    if (navigator.geolocation) {
                        navigator.geolocation.getCurrentPosition(position => {
                            let geolocation = {
                                lat: position.coords.latitude,
                                lng: position.coords.longitude
                            };
                            let circle = new google.maps.Circle({
                                center: geolocation,
                                radius: position.coords.accuracy
                            });
                            this.autocomplete.setBounds(circle.getBounds());
                        });
                    }
                }
            },

            onClickAdd() {
                this.error = null;

                if(this.autocompleteObj && this.autocompleteText) {
                    this.$emit('click');
                } else {
                    this.error = this.errorMsg;
                }

                this.clear();
            },

            onClickSearch() {
                this.$emit('search', (this.autocomplete.getPlace()) ? this.autocomplete.getPlace() : this.autocompleteText);
            },
        },
    }
</script>
