var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      staticClass: "vue-btn",
      class: [
        _vm.classes,
        {
          "vue-btn-loader-error": !_vm.isSuccess && !_vm.emptyStatus,
          "vue-btn-loader-success": _vm.isSuccess,
          "is-loading": _vm.isLoading,
        },
      ],
      on: { click: _vm.onSave },
    },
    [
      _vm.showSlot ? _vm._t("default") : _vm._e(),
      _vm._v(" "),
      _vm.isLoading
        ? [
            _c("span", { staticClass: "fa fa-circle" }),
            _vm._v(" "),
            _c("span", { staticClass: "fa fa-circle" }),
            _vm._v(" "),
            _c("span", { staticClass: "fa fa-circle" }),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }