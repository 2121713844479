var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "select",
    {
      class: _vm.classes,
      style: _vm.styleObject,
      attrs: { name: _vm.name, id: _vm.name, disabled: _vm.disabled },
      on: {
        change: function ($event) {
          return _vm.updateValue($event.target.value)
        },
      },
    },
    _vm._l(_vm.options, function (option) {
      return _c(
        "option",
        {
          domProps: {
            value: option.value,
            selected: option.value === _vm.value,
          },
        },
        [_vm._v("\n        " + _vm._s(option.label) + "\n    ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }