<template>
    <div class="register-wrap step-2">
        <div class="register-overlay step2"></div>

        <div class="register-steps">
            <div class="register-step">
                <div class="step-dot"></div>
                <div class="step-label">{{ initialData.lang.app_get_started }}</div>
            </div>
            <div class="register-step" :class="(step === 2) ? 'active' : ''">
                <div class="step-dot"></div>
                <div class="step-label">{{ initialData.lang.profile.title }}</div>
            </div>
            <div class="register-step" :class="(step === 3) ? 'active' : ''">
                <div class="step-dot"></div>
                <div class="step-label">{{ initialData.lang.pet.fields.pet_add }}</div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        props: ['step', 'initialData'],

        mounted() {
        }
    }
</script>
