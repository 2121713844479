<template>
    <div class="register-wrap step-1">
        <div class="register-overlay"></div>
        <div id="carouselExampleIndicators" class="carousel slide register-slider" data-ride="carousel">
            <ol class="carousel-indicators">
                <li data-target="#carouselExampleIndicators" data-slide-to="0" class="active"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
            </ol>
            <div class="carousel-inner register-carousel">
                <div class="carousel-item active">
                    <div class="register-slider-caption">
                        <h2>{{ initialData.lang.app_manage_pets }}</h2>
                    </div>
                    <div class="register-slider-icon bg-white">
                        <span class="icon-pawprints"></span>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="register-slider-caption">
                        <h2>{{ initialData.lang.app_renew_pet_licenses }}</h2>
                    </div>
                    <div class="register-slider-icon bg-white">
                        <span class="icon-flag"></span>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="register-slider-caption">
                        <h2>{{ initialData.lang.app_manage_pets }}</h2>
                    </div>
                    <div class="register-slider-icon bg-white">
                        <span class="icon-pawprints"></span>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="register-slider-caption">
                        <h2>{{ initialData.lang.app_renew_pet_licenses }}</h2>
                    </div>
                    <div class="register-slider-icon bg-white">
                        <span class="icon-flag"></span>
                    </div>
                </div>
                <div class="carousel-item">
                    <div class="register-slider-caption">
                        <h2>{{ initialData.lang.app_manage_pets }}</h2>
                    </div>
                    <div class="register-slider-icon bg-white">
                        <span class="icon-pawprints"></span>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        props : ['initialData'],
        mounted() {
        }
    }
</script>
