<template>
    <header class="d-flex flex-column justify-content-center">
        <div class="d-flex justify-content-between px-3 px-md-4 px-lg-5">
            <a href="http://www.milotag.com" target="_blank" class="navbar-brand">
                <img src="/images/logo-milo.svg" alt="milo">
            </a>
            <div>
                <div class="language-select d-inline-block">
                    <template v-for="(lang, index) in initialData.languages">
                        <template v-if="index === initialData.languages.length - 1"> |</template>
                        <a :href="lang.localUrl" >{{ lang.local }}</a>
                    </template>
                </div>
                <div class="logo-right d-inline-block ml-3 ml-lg-4">
                    <img :src="imgPath"  :alt="initialData.city">
                </div>
            </div>
        </div>
    </header>
</template>

<script>
    export default {
        props : ['initialData'],
        data() {
            return {
                imgPath : this.initialData.cityLogoSrc,
            }
        }
    }
</script>
