module.exports = {
    data: function () {
        return {}
    },

    methods: {

        getCurrentForm(formKey) {
            let currentForm = (window.localStorage.getItem(formKey) || null);
            if (currentForm && currentForm !== "undefined") {
                return JSON.parse(currentForm);
            }
            return {};
        },

        setCurrentForm(formKey, form) {
            return window.localStorage.setItem(formKey, JSON.stringify(form));
        },

        removeForm(formKey) {
            window.localStorage.removeItem(formKey);
        },

    },

    mounted() {
        $('input[autofill="off"]').disableAutofill();
    }


};