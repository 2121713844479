var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "p",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.error,
          expression: "error",
        },
      ],
      staticClass: "text-danger error-text",
    },
    [_vm._v(_vm._s(_vm.error))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }