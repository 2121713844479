var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-group date", class: _vm.formGroupClass },
    [
      _vm.label
        ? _c(
            "label",
            { staticClass: "control-label", attrs: { for: _vm.name } },
            [_vm._v(_vm._s(_vm.label))]
          )
        : _vm._e(),
      _vm._v(" "),
      _c("div", [
        _c("input", {
          ref: "input",
          staticClass: "form-control",
          attrs: {
            name: _vm.name,
            id: _vm.name,
            type: _vm.type,
            placeholder: _vm.placeholder,
            disabled: _vm.disabled,
            locale: _vm.locale,
          },
          domProps: { value: _vm.value },
          on: {
            input: function ($event) {
              return _vm.updateValue($event.target.value)
            },
          },
        }),
        _vm._v(" "),
        _vm._m(0),
      ]),
      _vm._v(" "),
      _vm.hasError
        ? _c("p", { staticClass: "text-danger helper-block" }, [
            _vm._v("\n        " + _vm._s(_vm.error) + "\n    "),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", { staticClass: "btn btn-dob" }, [
      _c("img", { attrs: { src: "/images/down-chevron.svg", alt: "chevron" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }