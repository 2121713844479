<template>
    <div>
        <auth-top-bar :initialData="initialData" />

        <div class="auth-wrap">
            <div class="auth-sidebar">
                <sidebar :initialData="initialData" />
            </div>
            <div class="auth-content">
                <main class="auth-main">
                    <div class="d-flex justify-content-center flex-column register-form-wrap">
                        <h1 class="jumbo-heading pb-4 mb-lg-3 text-center">{{ initialData.lang.app_activate_your_account }}</h1>
                        <p class="text-center">{{ initialData.lang.app_activate_your_account_text }}</p>
                        <div class="mt-4 d-flex flex-row justify-content-center">
                            <button
                                    id="btnResendVerificationEmail"
                                    class="btn btn-primary"
                                    @click="clickResendVerificationEmail()"
                            >
                                {{ initialData.lang.app_resend_verification_email }}
                            </button>
                        </div>
                        <h4 v-if="successMessageShow" class="text-success text-center mt-4">Email was sent!</h4>
                        <h4 v-if="errorMessageShow" class="text-danger text-center mt-4">Email address is invalid!</h4>
                    </div>


                </main>
            </div>
        </div>

        <footer class="d-flex flex-column justify-content-center">
            <div
                    class="d-flex justify-content-between align-items-center px-2 px-md-4 px-lg-5 footer-nav"
            >

            </div>
        </footer>

        <!-- Modal -->
        <div
                class="modal fade"
                id="exampleModalCenter"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalCenterTitle"
                aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5
                                class="modal-title"
                                id="exampleModalLongTitle"
                        >{{ initialData.lang.app_privacy_policy }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Milo Tag Inc {{ initialData.lang.app_privacy_policy }}
                        <br />
                        <br />
                        This {{ initialData.lang.app_privacy_policy }} describes how your personal information is collected, used, and shared when you visit or make a purchase from www.milotag.com (the “Site”).
                        <br />
                        <br />
                        <br />PERSONAL INFORMATION WE COLLECT
                        <br />
                        <br />When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information.”
                        <br />
                        <br />We collect Device Information using the following technologies:
                        <br />
                        <br />- “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.
                        <br />- “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.
                        <br />- “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.
                        <br />Additionally, when you make a purchase or attempt to make a purchase through the Site, we collect certain information from you, including your name, billing address, shipping address, payment information (including credit card numbers, email address, and phone number. We refer to this information as “Order Information.”
                        Although payment information will not be made available to our team or to the municipality to whom we provide this service on behalf of, other information collected like Name, Address, contact information, pet information, quantity of pets, fines, reports, messages sent and received are shared with the municipality.
                        When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Order Information.
                        <br />
                        <br />HOW DO WE USE YOUR PERSONAL INFORMATION?
                        <br />
                        <br />We use the Order Information that we collect generally to fulfill any orders placed through the Site (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations). Additionally, we use this Order Information to:
                        Communicate with you;
                        <br />
                        <br />Screen our orders for potential risk or fraud; and
                        When in line with the preferences you have shared with us, provide you with information or announcements from the responsible municipality relating to the products or services (licenses) offered.
                        We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and educational campaigns).
                        <br />
                        <br />SHARING YOUR PERSONAL INFORMATION
                        <br />
                        <br />We share your Personal Information with third parties to help us use your Personal Information, as described above. For example, we use Google Analytics to help us understand how our customers use the Site--you can read more about how Google uses your Personal Information here: https://www.google.com/intl/en/policies/privacy/. You can also opt-out of Google Analytics here: https://tools.google.com/dlpage/gaoptout.
                        <br />Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.
                        <br />
                        <br />DO NOT TRACK
                        <br />
                        <br />Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.
                        <br />
                        <br />YOUR RIGHTS
                        <br />
                        <br />If you are a resident of the municipality operating MiloTag, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.
                        <br />
                        <br />DATA RETENTION
                        <br />
                        <br />When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.
                        <br />
                        <br />MINORS
                        <br />
                        <br />The Site is not intended for individuals under the age which ownership of a pet is permitted in the municipality being served.
                        <br />
                        <br />CHANGES
                        <br />
                        <br />We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.
                        <br />
                        <br />CONTACT US
                        <br />
                        <br />For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at luisdiaz@fscogroup.com or by mail using the details provided below:
                        <br />
                        <br />3565 Jarry East, Suite 310, Montreal, QC, H1Z2G1, Canada
                    </div>
                    <div class="modal-footer">
                        <button
                                type="button"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                        >{{ initialData.lang.app_close }}</button>
                    </div>
                </div>
            </div>
        </div>







        <!-- Modal -->
        <div
                class="modal fade"
                id="exampleModalTerm"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalTermTitle"
                aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5
                                class="modal-title"
                                id="exampleModalLongTitle"
                        >{{ initialData.lang.app_privacy_policy }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Milo Tag Inc {{ initialData.lang.app_privacy_policy }}
                        <br />
                        <br />
                        This {{ initialData.lang.app_privacy_policy }} describes how your personal information is collected, used, and shared when you visit or make a purchase from www.milotag.com (the “Site”).
                        <br />
                        <br />
                        <br />PERSONAL INFORMATION WE COLLECT
                        <br />
                        <br />When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information.”
                        <br />
                        <br />We collect Device Information using the following technologies:
                        <br />
                        <br />- “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.
                        <br />- “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.
                        <br />- “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.
                        <br />Additionally, when you make a purchase or attempt to make a purchase through the Site, we collect certain information from you, including your name, billing address, shipping address, payment information (including credit card numbers, email address, and phone number. We refer to this information as “Order Information.”
                        Although payment information will not be made available to our team or to the municipality to whom we provide this service on behalf of, other information collected like Name, Address, contact information, pet information, quantity of pets, fines, reports, messages sent and received are shared with the municipality.
                        When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Order Information.
                        <br />
                        <br />HOW DO WE USE YOUR PERSONAL INFORMATION?
                        <br />
                        <br />We use the Order Information that we collect generally to fulfill any orders placed through the Site (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations). Additionally, we use this Order Information to:
                        Communicate with you;
                        <br />
                        <br />Screen our orders for potential risk or fraud; and
                        When in line with the preferences you have shared with us, provide you with information or announcements from the responsible municipality relating to the products or services (licenses) offered.
                        We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and educational campaigns).
                        <br />
                        <br />SHARING YOUR PERSONAL INFORMATION
                        <br />
                        <br />We share your Personal Information with third parties to help us use your Personal Information, as described above. For example, we use Google Analytics to help us understand how our customers use the Site--you can read more about how Google uses your Personal Information here: https://www.google.com/intl/en/policies/privacy/. You can also opt-out of Google Analytics here: https://tools.google.com/dlpage/gaoptout.
                        <br />Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.
                        <br />
                        <br />DO NOT TRACK
                        <br />
                        <br />Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.
                        <br />
                        <br />YOUR RIGHTS
                        <br />
                        <br />If you are a resident of the municipality operating MiloTag, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.
                        <br />
                        <br />DATA RETENTION
                        <br />
                        <br />When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.
                        <br />
                        <br />MINORS
                        <br />
                        <br />The Site is not intended for individuals under the age which ownership of a pet is permitted in the municipality being served.
                        <br />
                        <br />CHANGES
                        <br />
                        <br />We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.
                        <br />
                        <br />CONTACT US
                        <br />
                        <br />For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at luisdiaz@fscogroup.com or by mail using the details provided below:
                        <br />
                        <br />3565 Jarry East, Suite 310, Montreal, QC, H1Z2G1, Canada
                    </div>
                    <div class="modal-footer">
                        <button
                                type="button"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                        >{{ initialData.lang.app_close }}</button>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <div
                class="modal fade"
                id="exampleModalDisclaimer"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalDisclaimerTitle"
                aria-hidden="true"
        >
            <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5
                                class="modal-title"
                                id="exampleModalLongTitle"
                        >{{ initialData.lang.app_privacy_policy }}</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Milo Tag Inc {{ initialData.lang.app_privacy_policy }}
                        <br />
                        <br />
                        This {{ initialData.lang.app_privacy_policy }} describes how your personal information is collected, used, and shared when you visit or make a purchase from www.milotag.com (the “Site”).
                        <br />
                        <br />
                        <br />PERSONAL INFORMATION WE COLLECT
                        <br />
                        <br />When you visit the Site, we automatically collect certain information about your device, including information about your web browser, IP address, time zone, and some of the cookies that are installed on your device. Additionally, as you browse the Site, we collect information about the individual web pages or products that you view, what websites or search terms referred you to the Site, and information about how you interact with the Site. We refer to this automatically-collected information as “Device Information.”
                        <br />
                        <br />We collect Device Information using the following technologies:
                        <br />
                        <br />- “Cookies” are data files that are placed on your device or computer and often include an anonymous unique identifier. For more information about cookies, and how to disable cookies, visit http://www.allaboutcookies.org.
                        <br />- “Log files” track actions occurring on the Site, and collect data including your IP address, browser type, Internet service provider, referring/exit pages, and date/time stamps.
                        <br />- “Web beacons,” “tags,” and “pixels” are electronic files used to record information about how you browse the Site.
                        <br />Additionally, when you make a purchase or attempt to make a purchase through the Site, we collect certain information from you, including your name, billing address, shipping address, payment information (including credit card numbers, email address, and phone number. We refer to this information as “Order Information.”
                        Although payment information will not be made available to our team or to the municipality to whom we provide this service on behalf of, other information collected like Name, Address, contact information, pet information, quantity of pets, fines, reports, messages sent and received are shared with the municipality.
                        When we talk about “Personal Information” in this Privacy Policy, we are talking both about Device Information and Order Information.
                        <br />
                        <br />HOW DO WE USE YOUR PERSONAL INFORMATION?
                        <br />
                        <br />We use the Order Information that we collect generally to fulfill any orders placed through the Site (including processing your payment information, arranging for shipping, and providing you with invoices and/or order confirmations). Additionally, we use this Order Information to:
                        Communicate with you;
                        <br />
                        <br />Screen our orders for potential risk or fraud; and
                        When in line with the preferences you have shared with us, provide you with information or announcements from the responsible municipality relating to the products or services (licenses) offered.
                        We use the Device Information that we collect to help us screen for potential risk and fraud (in particular, your IP address), and more generally to improve and optimize our Site (for example, by generating analytics about how our customers browse and interact with the Site, and to assess the success of our marketing and educational campaigns).
                        <br />
                        <br />SHARING YOUR PERSONAL INFORMATION
                        <br />
                        <br />We share your Personal Information with third parties to help us use your Personal Information, as described above. For example, we use Google Analytics to help us understand how our customers use the Site--you can read more about how Google uses your Personal Information here: https://www.google.com/intl/en/policies/privacy/. You can also opt-out of Google Analytics here: https://tools.google.com/dlpage/gaoptout.
                        <br />Finally, we may also share your Personal Information to comply with applicable laws and regulations, to respond to a subpoena, search warrant or other lawful request for information we receive, or to otherwise protect our rights.
                        <br />
                        <br />DO NOT TRACK
                        <br />
                        <br />Please note that we do not alter our Site’s data collection and use practices when we see a Do Not Track signal from your browser.
                        <br />
                        <br />YOUR RIGHTS
                        <br />
                        <br />If you are a resident of the municipality operating MiloTag, you have the right to access personal information we hold about you and to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this right, please contact us through the contact information below.
                        <br />
                        <br />DATA RETENTION
                        <br />
                        <br />When you place an order through the Site, we will maintain your Order Information for our records unless and until you ask us to delete this information.
                        <br />
                        <br />MINORS
                        <br />
                        <br />The Site is not intended for individuals under the age which ownership of a pet is permitted in the municipality being served.
                        <br />
                        <br />CHANGES
                        <br />
                        <br />We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.
                        <br />
                        <br />CONTACT US
                        <br />
                        <br />For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at luisdiaz@fscogroup.com or by mail using the details provided below:
                        <br />
                        <br />3565 Jarry East, Suite 310, Montreal, QC, H1Z2G1, Canada
                    </div>
                    <div class="modal-footer">
                        <button
                                type="button"
                                class="btn btn-secondary"
                                data-dismiss="modal"
                        >{{ initialData.lang.app_close }}</button>
                    </div>
                </div>
            </div>
        </div><!-- End Displaimer modal -->

    </div>
</template>


<style>
    .relativeContainer {
        position: relative;
        height: 25px;
    }

    .commonFields {
        text-align: center;
        height: 100%;
    }

    .commonColors {
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
    }

    .UppercaseField-effect,
    .UppercaseColor-effect,
    .LowercaseField-effect,
    .LowercaseColor-effect,
    .NumberSymbolField-effect,
    .NumberSymbolColor-effect,
    .minimumCharactersField-effect,
    .minimumCharactersColor-effect {
        animation-duration: 0.4s;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
        animation-direction: normal;
    }

    .UppercaseField-effect {
        animation-name: animate1;
    }

    .UppercaseColor-effect {
        animation-name: animate2;
    }

    .LowercaseField-effect {
        animation-name: animate3;
    }

    .LowercaseColor-effect {
        animation-name: animate4;
    }

    .NumberSymbolField-effect {
        animation-name: animate5;
    }

    .NumberSymbolColor-effect {
        animation-name: animate6;
    }

    .minimumCharactersField-effect {
        animation-name: animate7;
    }

    .minimumCharactersColor-effect {
        animation-name: animate8;
    }

    @keyframes animate1 {
        100% {
            color: white;
        }
    }

    @keyframes animate2 {
        0% {
            width: 0%;
        }

        100% {
            background: green;
            width: 100%;
        }
    }

    @keyframes animate3 {
        100% {
            color: white;
        }
    }

    @keyframes animate4 {
        0% {
            width: 0%;
        }

        100% {
            background: green;
            width: 100%;
        }
    }

    @keyframes animate5 {
        100% {
            color: white;
        }
    }

    @keyframes animate6 {
        0% {
            width: 0%;
        }

        100% {
            background: green;
            width: 100%;
        }
    }

    @keyframes animate7 {
        100% {
            color: white;
        }
    }

    @keyframes animate8 {
        0% {
            width: 0%;
        }

        100% {
            background: green;
            width: 100%;
        }
    }

    .px-2 {
        padding: 0 0.5rem;
    }

    .mx-2 {
        margin: 0 0.5rem;
    }
</style>


<script>
    let upperCaseGood = false;
    let lowerCaseGood = false;
    let numberSymbolGood = false;
    let minimumCharacterGood = false;

    function setup() {
        let inputField = document.querySelector('[name="inputPassword"]');
        inputField = document.querySelector("#inputPassword");

        initializeInput(inputField);

        inputField.addEventListener("keyup", () => {
            initializeInput(inputField);
        });
    }

    function initializeInput(input) {
        let pattern = /[A-Z]/;
        let upperCaseBoolean = pattern.test(input.value);

        if (upperCaseBoolean === true && upperCaseGood === false) {
            upperCaseGood = true;
            activateColors(false, "UppercaseField", "UppercaseColor");
        } else if (upperCaseBoolean === false && upperCaseGood === true) {
            upperCaseGood = false;
            activateColors(true, "UppercaseField", "UppercaseColor");
        }

        pattern = /[a-z]/;
        let lowerCaseBoolean = pattern.test(input.value);

        if (lowerCaseBoolean === true && lowerCaseGood === false) {
            lowerCaseGood = true;
            activateColors(false, "LowercaseField", "LowercaseColor");
        } else if (lowerCaseBoolean === false && lowerCaseGood === true) {
            lowerCaseGood = false;
            activateColors(true, "LowercaseField", "LowercaseColor");
        }

        pattern = /[0-9]/;
        let numberBoolean = pattern.test(input.value);
        pattern = /[~`!@#$%^&*()\-_+=]/;
        let symbolBoolean = pattern.test(input.value);

        let numberSymbolBoolean = false;
        if (numberBoolean === true || symbolBoolean === true) {
            numberSymbolBoolean = true;
        }

        if (numberSymbolBoolean === true && numberSymbolGood === false) {
            numberSymbolGood = true;
            activateColors(false, "NumberSymbolField", "NumberSymbolColor");
        } else if (numberSymbolBoolean === false && numberSymbolGood === true) {
            numberSymbolGood = false;
            activateColors(true, "NumberSymbolField", "NumberSymbolColor");
        }

        if (input.value.length >= 10 && minimumCharacterGood === false) {
            minimumCharacterGood = true;
            activateColors(false, "minimumCharactersField", "minimumCharactersColor");
        } else if (input.value.length < 10 && minimumCharacterGood === true) {
            minimumCharacterGood = false;
            activateColors(true, "minimumCharactersField", "minimumCharactersColor");
        }
    }

    function activateColors(switchDirection, field, color) {
        let x = document.querySelector("#" + field);
        let y = document.querySelector("#" + color);

        x.classList.remove(field + "-effect");
        y.classList.remove(color + "-effect");
        void x.offsetWidth;
        void y.offsetWidth;
        x.classList.add(field + "-effect");
        y.classList.add(color + "-effect");

        if (switchDirection === false) {
            y.style.animationDirection = "normal";
            x.style.animationDirection = "normal";
        } else if (switchDirection === true) {
            y.style.animationDirection = "Reverse";
            x.style.animationDirection = "Reverse";
        }
    }

    export default {
        components: {
            "error-ele": require("../../components/form/Error").default
        },
        props: ["initialData"],

        data() {
            return {
                form: {
                    name: "No name",
                    email: null,
                    password: null,
                    password_confirmation: null
                },
                acceptTerms: false,
                cruelty: false,
                acceptAll: false,
                emailAddress: null,
                successMessageShow: false,
                errorMessageShow: false,
            };
        },

        methods: {
            saveForm() {
                $("#btnSave").attr("disabled", "disabled");
                let url =
                    this.apiRequestUrl + "/" + this.initialData.current_lang + "/register";
                axios
                    .post(url, this.form)
                    .then(response => {
                        window.location.href =
                            "/" + this.initialData.current_lang + "/createProfile";
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            this.formError = error.response.data;
                        }
                        $("#btnSave").removeAttr("disabled");
                    });
            },
            clickResendVerificationEmail() {
                this.successMessageShow = false;
                this.errorMessageShow = false;
                $("#btnResendVerificationEmail").attr("disabled", "disabled");
                let url =
                    this.apiRequestUrl + "/" + this.initialData.current_lang + "/resend-verification-email";
                axios.post(url, {
                        email: this.initialData.emailAddress,
                    })
                    .then(response => {
                        $("#btnResendVerificationEmail").removeAttr("disabled");
                        this.successMessageShow = true;
                    })
                    .catch(error => {
                        if (error.response.status === 422) {
                            this.formError = error.response.data;
                        }
                        this.errorMessageShow = true;
                        $("#btnResendVerificationEmail").removeAttr("disabled");
                    });
            },
            privacyPolicy() {
                this.acceptTerms = !this.acceptTerms;
                this.tryThis();
            },

            tryThis() {
                this.acceptAll = this.acceptTerms === true;
            }
        },

        mounted() {
            this.form = this.getCurrentForm("register-form");
            setup();
        }
    };
</script>
