<template>
    <button
            class="vue-btn"
            :class="[classes ,{'vue-btn-loader-error': !isSuccess && !emptyStatus, 'vue-btn-loader-success': isSuccess, 'is-loading': isLoading }]" @click="onSave">
        <slot v-if="showSlot"></slot>
        <template v-if="isLoading">
            <span class="fa fa-circle"></span>
            <span class="fa fa-circle"></span>
            <span class="fa fa-circle"></span>
        </template>
    </button>
</template>

<script>
    export default {
        name: 'button-loader',
        props: {
            isLoading: {
                default: false
            },
            status: {
                type: String | Boolean,
                default: ''
            },
            classes: {
                type: String,
                default: ''
            }
        },
        computed: {
            loading () {
                return this.isLoading
            },
            isSuccess () {
                return this.status === 'success' || this.status === true
            },
            emptyStatus () {
                return !this.status.length
            },
            showSlot () {
                return this.isSuccess || this.loading || this.emptyStatus
            }
        },
        methods: {
            onSave(object){
                this.$emit('click',object);
            }
        },
    }
</script>

<style lang="scss" scoped>
    @keyframes blink {
        0% {
            opacity: .2;
        }
        20% {
            opacity: 1;
        }
        100% {
            opacity: .2;
        }
    }
    .is-loading span {
        animation-name: blink;
        animation-duration: 1.4s;
        animation-iteration-count: infinite;
        animation-fill-mode: both;
        vertical-align:middle;
        font-size:6px;
    }
    .vue-btn span.fa-circle{
        color:#FFF;
    }
    .is-loading span:nth-child(2) {
        animation-delay: .2s;
    }
    .is-loading span:nth-child(3) {
        animation-delay: .4s;
    }
    .vue-btn{
        cursor: pointer;
    }

</style>