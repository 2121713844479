<template>
    <select :class="classes"
            :name="name"
            :id="name"
            @change="updateValue($event.target.value)"
            :disabled="disabled"
            :style="styleObject"
    >
        <option v-for="option in options" :value="option.value" :selected="option.value === value">
            {{ option.label }}
        </option>
    </select>
</template>

<script>
    export default {
        props: {
            'value': {
                default: ''
            },
            'classes': {
                default: ''
            },
            'options': {
                type: Array,
                default: ''
            },
            'name': {
                type: String,
                default: ''
            },
            'disabled': {
                type: Boolean,
                default: false
            },
            'styleObject': {
                type: Object,
                default: null
            },
        },

        methods: {
            updateValue: function (value) {
                this.$emit('input', value);
            }
        },
    }
</script>
