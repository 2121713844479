var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showAddBtn || _vm.showSearchBtn
    ? _c("div", [
        _c("div", { staticClass: "input-group" }, [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.autocompleteText,
                expression: "autocompleteText",
              },
            ],
            ref: "autocomplete",
            class: _vm.classname,
            attrs: {
              type: "text",
              id: _vm.id,
              placeholder: _vm.placeholder,
              autocomplete: "new-password",
              "aria-describedby": "basic-addon2",
              autofill: "off",
            },
            domProps: { value: _vm.autocompleteText },
            on: {
              focus: function ($event) {
                return _vm.onFocus()
              },
              blur: function ($event) {
                return _vm.onBlur()
              },
              change: _vm.onChange,
              keypress: _vm.onKeyPress,
              keyup: _vm.onKeyUp,
              input: function ($event) {
                if ($event.target.composing) return
                _vm.autocompleteText = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "input-group-append" }, [
            _vm.showAddBtn
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-white",
                    on: { click: _vm.onClickAdd },
                  },
                  [_vm._v("Add Location")]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.showSearchBtn
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-outline-white",
                    on: { click: _vm.onClickSearch },
                  },
                  [_vm._v("Search")]
                )
              : _vm._e(),
          ]),
        ]),
        _vm._v(" "),
        _vm.error
          ? _c("div", { staticClass: "text-danger error-text" }, [
              _vm._v("\n        " + _vm._s(_vm.error) + "\n    "),
            ])
          : _vm._e(),
      ])
    : _c("div", [
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.autocompleteText,
              expression: "autocompleteText",
            },
          ],
          ref: "autocomplete",
          class: _vm.classname,
          attrs: {
            type: "text",
            id: _vm.id,
            placeholder: _vm.placeholder,
            autocomplete: "new-password",
            "aria-describedby": "basic-addon2",
            autofill: "off",
          },
          domProps: { value: _vm.autocompleteText },
          on: {
            focus: function ($event) {
              return _vm.onFocus()
            },
            blur: function ($event) {
              return _vm.onBlur()
            },
            change: _vm.onChange,
            keypress: _vm.onKeyPress,
            keyup: _vm.onKeyUp,
            input: function ($event) {
              if ($event.target.composing) return
              _vm.autocompleteText = $event.target.value
            },
          },
        }),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }