var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "register-wrap step-2" }, [
    _c("div", { staticClass: "register-overlay step2" }),
    _vm._v(" "),
    _c("div", { staticClass: "register-steps" }, [
      _c("div", { staticClass: "register-step" }, [
        _c("div", { staticClass: "step-dot" }),
        _vm._v(" "),
        _c("div", { staticClass: "step-label" }, [
          _vm._v(_vm._s(_vm.initialData.lang.app_get_started)),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "register-step", class: _vm.step === 2 ? "active" : "" },
        [
          _c("div", { staticClass: "step-dot" }),
          _vm._v(" "),
          _c("div", { staticClass: "step-label" }, [
            _vm._v(_vm._s(_vm.initialData.lang.profile.title)),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "register-step", class: _vm.step === 3 ? "active" : "" },
        [
          _c("div", { staticClass: "step-dot" }),
          _vm._v(" "),
          _c("div", { staticClass: "step-label" }, [
            _vm._v(_vm._s(_vm.initialData.lang.pet.fields.pet_add)),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }